.quoteContainer {
  padding: 20px;
  width: 40%;
  padding:140px ;
  color: #CCCCCC;
}

.quoteText {
  margin-bottom: 10px;
  font-size: 4.5em;
}

.author{
  font-size: 2em;
  font-weight: bold;
  text-align: right;
}
.blue {
  color: #0066CC;
}
.red {
  color: #FF0000
}


.parallaxContainer {
  width: 100%;
  height: 100px; /* Adjust height as needed */
}

.parallaxContent {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
  
.spinningImage {
  animation: spin 3s linear infinite; /* Adjust animation duration and timing function */
  width: 100px;
}
.resumeContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:center;
}


.downloadIcon{
  width: 65px;
  height: auto;
  padding-right: 20px;

}
    
@keyframes spin {
  from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
  }
}    

@media screen and (max-width: 1600px) {
  .quoteText{
    font-size: 3.5em;
    
  }
}  

@media screen and (max-width: 1200px) {

  .quoteContainer{
    width:85%;
    padding: 25px;
  }
  .quoteText{
    font-size: 4.5em;
    
  }
}
  